import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Layout from "../components/layout";
import SEO from "../components/seo";

const baseURL = "https://d27immcr5js8nz.cloudfront.net";

const images = [
    {
        src: `${baseURL}/1.jpg`,
        thumbnail: `${baseURL}/thumbnails/1.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 134,
        caption: `Judy and Lucille had a ball at the wedding of Brittany and Mike (Judy’s daughter). Lucille showed many up with her great moves on the dance floor that night. Here she was 102.`,
    },
    {
        src: `${baseURL}/2.jpg`,
        thumbnail: `${baseURL}/thumbnails/2.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Always one of the best interviews of the day. This interview was in Houston. Not every interview made the book, but this one still had a great story to go along with it!`,
    },
    {
        src: `${baseURL}/3.jpg`,
        thumbnail: `${baseURL}/thumbnails/3.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Lucille’s signature look. Always a flower on her shoulder and a ribbon in her hair.`,
    },
    {
        src: `${baseURL}/0.jpg`,
        thumbnail: `${baseURL}/thumbnails/0.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
    },
    {
        src: `${baseURL}/5.jpg`,
        thumbnail: `${baseURL}/thumbnails/5.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `The Conservatory gave quite the sendoff for Lucille to go throw out the first pitch! (Chapter 26) Judy hid her pain from Lyme’s disease so that Lucille was none the wiser. The event went off without a hitch and a dream was realized.`,
    },
    {
        src: `${baseURL}/6.jpg`,
        thumbnail: `${baseURL}/thumbnails/6.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        caption: `A young Lucille, so full of life with her whole future ahead of her.`,
    },
    {
        src: `${baseURL}/7.jpg`,
        thumbnail: `${baseURL}/thumbnails/7.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Nothing made her scream and shout like being “in the money” at the end or a duplicate bridge tournament!`,
    },
    {
        src: `${baseURL}/8.jpg`,
        thumbnail: `${baseURL}/thumbnails/8.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Lucille didn’t talk much about her days as a nurse during WWII. She saw a great deal of injuries and heartache, but did her duty with a smile and a strong spirit. This is a picture of picture that hung in the halls of Eden Estates, where all those that served their country in one capacity or another were honored.`,
    },
    {
        src: `${baseURL}/9.jpg`,
        thumbnail: `${baseURL}/thumbnails/9.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Lucille recovered quite comfortably at Judy’s place for a while. Here, she insisted on eating in front of the television so she could watch the RANGERS (of course). (Chapter 32).`,
    },
    {
        src: `${baseURL}/10.jpg`,
        thumbnail: `${baseURL}/thumbnails/10.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `This is the gold dress that Lucille so proudly showed us during that first meeting. (Chapter 1).`,
    },
    {
        src: `${baseURL}/11.jpg`,
        thumbnail: `${baseURL}/thumbnails/11.jpg`,
        thumbnailWidth: 154,
        thumbnailHeight: 200,
        caption: `Our first meeting at Eden Estates. Chapter 1.`,
    },
    {
        src: `${baseURL}/12.jpg`,
        thumbnail: `${baseURL}/thumbnails/12.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `One of our many lunch outtings. Lucille tried Calamarie for the first time. No, she wasn’t a fan of it, but she did at least try it!`,
    },
    {
        src: `${baseURL}/13.jpg`,
        thumbnail: `${baseURL}/thumbnails/13.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Lucille was always up for some great advice. This luncheon (Chapter 13) I learned a valuable lesson about vacations and family life.`,
    },
    {
        src: `${baseURL}/14.jpg`,
        thumbnail: `${baseURL}/thumbnails/14.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Nobody could pull off pink and black like Lucille. (Chapter 14) Here we are on the way to Rise n°1 for Soufflés and a great story about the “perfect catch” that she so wisely threw back.`,
    },
    {
        src: `${baseURL}/15.jpg`,
        thumbnail: `${baseURL}/thumbnails/15.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Not every outing made the book, but this trip to the Bush museum was one of my favorite memories. George W had a wonderful art display and we must have spent at least 15 minutes discussing each piece. Such a treat!`,
    },
    {
        src: `${baseURL}/16.jpg`,
        thumbnail: `${baseURL}/thumbnails/16.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Mother’s Day in Memphis! The Peabody hotel was a blast, but not nearly as fun as the television and radio interviews that would follow. At this point, she had no idea I was planning to stop by St. Jude’s. (Chapter 19).`,
    },
    {
        src: `${baseURL}/17.jpg`,
        thumbnail: `${baseURL}/thumbnails/17.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `Memphis, just before we went in for an interview on Channel 3. As Lucille loved to say, “The Talent has arrived!”`,
    },
    {
        src: `${baseURL}/18.jpg`,
        thumbnail: `${baseURL}/thumbnails/18.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        caption: `I don’t think Marybeth Conley could have imagined what she was getting herself into when she invited Lucille and me to come to the radio station after the television interview. I don’t think I had ever laughed that hard and that long.`,
    },
    {
        src: `${baseURL}/19.jpg`,
        thumbnail: `${baseURL}/thumbnails/19.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Planes, trains, and automobiles at 101 years of age – NO PROBLEM!`,
    },
    {
        src: `${baseURL}/20.jpg`,
        thumbnail: `${baseURL}/thumbnails/20.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Lucille outside the front doors of Eden Estate. Always so stylish, so happy, and so excited to spend time together.`,
    },
    {
        src: `${baseURL}/21.jpg`,
        thumbnail: `${baseURL}/thumbnails/21.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Lucille and Emily, aka “Creepy Girl”.`,
    },
    {
        src: `${baseURL}/22.jpg`,
        thumbnail: `${baseURL}/thumbnails/22.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        caption: `Lucille and Judy making Apple pie in Judy’s kitchen. Sometimes the best things in life are the simple things. (Chapter 12).`,
    },
    {
        src: `${baseURL}/23.jpg`,
        thumbnail: `${baseURL}/thumbnails/23.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        caption: `One of so many moments where Lucille and Judy were out and about, making friends and even better, making memories.`,
    },
    {
        src: `${baseURL}/24.jpg`,
        thumbnail: `${baseURL}/thumbnails/24.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `Just before going on Good Morning Texas!`,
    },
    {
        src: `${baseURL}/25.jpg`,
        thumbnail: `${baseURL}/thumbnails/25.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 113,
    },
    {
        src: `${baseURL}/26.jpg`,
        thumbnail: `${baseURL}/thumbnails/26.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 113,
        caption: `Not only did Lucille conquer her fear of flying (Chapter 20) but she taught Judy a lesson that she would soon apply to her own fear. (Chapter 21).`,
    },
    {
        src: `${baseURL}/4.jpg`,
        thumbnail: `${baseURL}/thumbnails/4.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
    },
    {
        src: `${baseURL}/27.jpg`,
        thumbnail: `${baseURL}/thumbnails/27.jpg`,
        thumbnailWidth: 200,
        thumbnailHeight: 96,
        caption: `Lucille at the radio station with Dr. Anderson, Walter and Judy. Her many guest appearances on the Staying Young Show made her a listener favorite!`,
    },
    {
        src: `${baseURL}/30.jpg`,
        thumbnail: `${baseURL}/thumbnails/30.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
    },
    {
        src: `${baseURL}/28.jpg`,
        thumbnail: `${baseURL}/thumbnails/28.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
    },
    {
        src: `${baseURL}/29.jpg`,
        thumbnail: `${baseURL}/thumbnails/29.jpg`,
        thumbnailWidth: 150,
        thumbnailHeight: 200,
        caption: `The moment of truth. The first time Judy saw Lucille without her hair. That trip to the wig shop was more than just a barrel of laughs, it was an incredible bonding experience. (Chapter 10).`,
    },
];

function Gallery() {
    return (
        <Layout>
            <SEO keywords={["Judy Gaman", "Lucille Fleming"]} title="Gallery" />
            <section className="text-lll-purple-600 italic">
                <h1 className="lll-title font-bold text-center text-lll-purple-600 text-3xl sm:text-4xl">
                    {"Gallery"}
                </h1>
                {images.map((image, index) => {
                    return (
                        <div
                            key={index}
                            className="mx-auto mb-12 flex flex-col items-center sm:max-w-sm"
                        >
                            <LazyLoadImage
                                src={image.src}
                                alt={image.caption}
                                height={800}
                            />
                            <div>{image.caption}</div>
                        </div>
                    );
                })}
            </section>
        </Layout>
    );
}

export default Gallery;
